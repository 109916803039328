import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import leaderboard from '../assets//coming-soon-png.png'
// import TableItems from "../TableItems/TableItems.jsx";
import './Tabs.css'
import ListItems from "../ListItems/ListItems.jsx";

function CustomTabPanel(props) {
    const { children, value, index, tabData, newItems, ...other } = props;
    const newTagItems = []

    let newDate = new Date();

    const handleNewItems = (items) => {

        items?.map((data, index) => {
            let prevDate = new Date(data?.publishedDate);
            var dateCheck =
                newDate.getMonth() + 1 === prevDate.getMonth() + 1 &&
                    newDate.getFullYear() === prevDate.getFullYear() &&
                    Math.abs(newDate.getDate() - prevDate.getDate()) < 5
                    ? true
                    : false;
            if (dateCheck === true && data.type !== 'header1') {
                newTagItems.push(data)
            }
            if (data.subItems.length > 0) {
                handleNewItems(data.subItems)
            }

        })
        return newTagItems
    }

    handleNewItems(newItems)

    const data = tabData.map((value, i) => {
        if (i === index && value.label !== "Leaderboard" && value.label !== "Newitems") {
            return (
                < div
                    dangerouslySetInnerHTML={{
                        __html: value?.content,
                    }
                    }
                >
                </div>
            )
        } else if (i === index && value.label === "Leaderboard") {
            // return <TableItems content={value.content} key={i} />
            return (
                <div className='tab-leader-board'>
                    <img
                        src={leaderboard}
                        alt="slide"
                        loading="lazy"
                        width={'300px'}
                        height={'150px'}
                    />
                </div>
            )
        }
        else if (i === index && value.label === "Newitems") {
            return <ListItems newItems={newTagItems} />
        }
        else return null
    })
    return (
        <div
            role="tabpanel"
            key={index}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div style={{ marginTop: '10px', maxHeight: '218px', overflowY: 'scroll' }}>{data}</div>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index, data) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        content: data,
    };
}

export default function BasicTabs(props) {
    const { tabData, newItems } = props

    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '105vh', border: '1px solid #85b1ce', margin: '0px 20px 20px 0px', padding: '4px 16px 16px 16px', maxHeight: '300px' }}>
            <Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {tabData.map((data, index) =>
                        <Tab key={index} label={data.label} {...a11yProps(index)} sx={{ textTransform: 'none', fontFamily: 'inherit', fontSize: '1rem' }} />
                    )}
                </Tabs>
            </Box>
            <CustomTabPanel tabData={tabData} newItems={newItems} value={value} index={0}>
            </CustomTabPanel>
            <CustomTabPanel tabData={tabData} newItems={newItems} value={value} index={1}>
            </CustomTabPanel>
            <CustomTabPanel tabData={tabData} newItems={newItems} value={value} index={2}>
            </CustomTabPanel>
        </Box>
    );
}
