import React, { useState } from "react";
import "./App.css";
import { GoogleLogin } from "@react-oauth/google";
import Homepage from "../src/Homepage/HomePage";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

const App = () => {
  const [auth, setAuth] = useState("");
  const responseMessage = (response) => {
    localStorage.setItem("credential", response.credential);
    setAuth(response.clientId);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <>
      {localStorage.getItem("credential") ? (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <div className="loginScreen">
          <section className="container">
            <div className="login-container">
              <div className="form-container">
                <h1 className="opacity">LOGIN</h1>
                <GoogleLogin
                  onSuccess={responseMessage}
                  onError={errorMessage}
                />
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default App;
